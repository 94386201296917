// 
// AgroBrasilTV
// https://www.figma.com/file/TyTlaOI3uL4Vr6N83NzjKh/AgroBrasilTV-%5BUIDesign%5D?node-id=304%3A14049
// https://www.figma.com/file/t8bLdcIlTbinFGpYKJiCbd/AgroBrasilTV-%5BUIDesign%5D-(Copy)?t=i4r9jjIvUcfjfuzh-0
// 
// https://css-tricks.com/how-do-you-remove-unused-css-from-a-site/
// 

const bootstrap = require('bootstrap');

require("fslightbox");

function App() {
    this.createLive();
    const x = window.matchMedia("(max-width: 1023px) and (orientation: portrait)");
    if(x.matches) this.offcanvasMenu();
    // x.addListener(() => this.offcanvasMenu());
};

App.prototype.createLive = function() {
    const url = String(document.getElementById('btn-aovivo').dataset.url);
    if(url.length > 0) {
        const iframe = document.createElement('iframe');
        iframe.setAttribute('id','aovivo');
        iframe.setAttribute('src', document.getElementById('btn-aovivo').dataset.url);
        iframe.setAttribute('allowtransparency','true');
        iframe.setAttribute('scrolling','no');
        iframe.setAttribute('allowfullscreen','allowfullscreen');
        iframe.setAttribute('width','1024');
        iframe.setAttribute('height','768');
        fsLightboxInstances["live"].props.sources.push(iframe);
        fsLightboxInstances["live"].props.onOpen = () => iframe.closest('.fslightbox-absoluted').removeAttribute('style');    
    }
};

App.prototype.offcanvasMenu = function() {
    // if(window.matchMedia("(max-width: 1023px) and (orientation: portrait)").matches) {
        console.log('mobile');
        try {
            const meenuOffcanvas = new bootstrap.Offcanvas('#offcanvasResponsive');
            document.addEventListener('click',e => {
                if(e.target.classList.contains('nav-link')){
                    e.preventDefault();
                    e.stopPropagation();
                    const anchor = String(e.target.getAttribute('href')).substr(1);
                    const el = document.getElementById('offcanvasResponsive');
                    el.addEventListener('hidden.bs.offcanvas', function __tmp(event){
                        el.removeEventListener('hidden.bs.offcanvas', __tmp);
                        document.querySelector('[name="'+anchor+'"]').scrollIntoView()
                    });
                    meenuOffcanvas.toggle();
                    return false;
                }
                return true;
            });
        } catch(err) {
            console.error('error', err);
        }    
    // }
};

App.prototype.run = function() {
    console.log('app run!');
};

(function(readyState){
    const a = new App();
    return readyState == 'loading' ? document.addEventListener("DOMContentLoaded", e => a.run()) : a.run();
})(document.readyState, document, window);
